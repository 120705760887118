import { addCommas, convertQrToNumber } from "src/lib/helpers";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Alert,
  Nav,
  NavItem,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Spinner,
  FormText,
} from 'reactstrap';
import API from "src/api";
import NotifToast from "src/components/notificationModal/NotifToast";

export default class ModalUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      required: ['serial_range', 'roll', 'shipping_date'],
      body: {
        manufacturer_id: null,
        serial_range: [],
        shipping_date: '',
      },
      notif: {
        show: false,
        title: '',
        message: '',
        status: true,
      },
      downloadPath: null,
      previewDatas: [],
      footerAlert: false,
    }
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is required'
    }
    else {
      delete error[name]
    }

    if (name === 'serial_range') {
      if (this.state.downloadPath) {
        this.setState({
          downloadPath: null,
        });
      }

      // split by enter
      const serials = value.split(/[\n]+/);
      let previewDatas = [];
      let rollDatas = [];
      let bodyUpdate = "";
      let total = 0;

      for (let i = 0; i < serials.length; i++) {
        previewDatas[i] = {};
        if(!serials[i]) continue;

        let qty = 0;

        const serialSpace = serials[i].split(/\s+/);
        if (serialSpace.length > 1) {
          rollDatas[i] = serialSpace[0];
          serials[i] = serialSpace[serialSpace.length - 1];
        }

        if (serials[i].includes('-')) {
          const serialsSplit = serials[i].split('-');
          if (serialsSplit.length > 1) {
            const start = parseInt(serialsSplit[0]);
            const end = parseInt(serialsSplit[1]);
            qty = end - start + 1;
          } else {
            qty = 1;
          }
        }

        bodyUpdate += serials[i] + '\n';

        previewDatas[i]['no'] = i + 1;
        previewDatas[i]['serial'] = serials[i];
        previewDatas[i]['roll'] = rollDatas[i];
        previewDatas[i]['qty'] = qty;
        total += qty;
      }

      if (previewDatas.length > 1) {
        previewDatas.push({
          no: '',
          serial: 'Total',
          qty: total
        });
        newBody[name] = bodyUpdate;
      }
      
      newBody['roll'] = rollDatas.join(' ');

      this.setState({
        previewDatas: previewDatas.filter((data) => data.serial),
      });
    } else if (name === 'roll') {
      const previewDatas = this.state.previewDatas;
      const rolls = value.split(/[\s,]+/);
      previewDatas.forEach((data, index) => {
        previewDatas[index]['roll'] = rolls[index];
      });

      this.setState({
        previewDatas: previewDatas,
      });
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length === 0 && this.state.required.includes(key)
      } else {
        return !value && this.state.required.includes(key)
      }
    });
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({
      // notif: {
      //   ...this.state.notif,
      //   show: true,
      //   title: 'Processing...',
      // },
      buttonLoading: true,
    });
    let body = { ...this.state.body };
    delete body['roll'];
    body['serial_range'] = this.state.previewDatas?.map((serial) => {
      if (serial.serial !== 'Total') {
        if (!serial.roll) {
          this.setState({ ...this.state.error, roll: 'The number of Roles is not the same as the serial' });
        }

        return {
          range: serial.serial,
          roll: parseInt(serial.roll),
        }
      }
    });
    body['serial_range'] = body['serial_range'].filter((serial) => serial);
    if (body['serial_range'].find((serial) => !serial?.roll)) {
      this.setState({ error: { roll: 'The number of Roles is not the same as the serial' } });
      return;
    }

    // alert(JSON.stringify(body)); return;
    API.put('serial-code', body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          buttonLoading: false,
        });
        if (result.status) {
          this.props.refresh();
          this.setState({
            downloadPath: result.data?.path_file,
            notif: {
              title: 'Success',
              status: result.status,
              message: result.message,
            },
          });

          setTimeout(() => {
            this.setState({
              notif: { show: false }
            });
          }, 1500);
        }
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
            status: false,
          }
        });
      })
  }

  onClose = () => {
    if (this.state.buttonLoading) return;
    this.props.close();
    this.setState({
      error: {},
      disabled: true,
      buttonLoading: false,
      body: {
        manufacturer_id: null,
        start_serial: '',
        end_serial: '',
        roll: '',
        shipping_date: '',
      },
      notif: {
        show: false,
        title: '',
        message: '',
        status: true,
      },
      downloadPath: null,
      qty: 0,
      previewDatas: [],
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.onClose()} size="lg">
          <ModalHeader toggle={() => this.onClose()}>Update Roll & Shipping Date</ModalHeader>
          <ModalBody>
            <div className="row">
              <Form className="col-md-5">
                <FormGroup>
                  <Label for="exampleEmail">
                    Serial Range
                  </Label>
                  <Input
                    name="serial_range"
                    placeholder="Example: 001-002"
                    type="textarea"
                    onChange={this.onChange}
                    value={this.state.body['serial_range']}
                    required
                    invalid={this.state.error['serial_range'] ? true : false}
                  />
                  <FormText>Use enter to add multiples </FormText>
                  <FormFeedback >{this.state.error['serial_range']}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail">
                    Roll Number
                  </Label>
                  <Input
                    name="roll"
                    placeholder="Enter roll number"
                    type="text"
                    onChange={this.onChange}
                    value={this.state.body['roll']}
                    required
                    invalid={this.state.error['roll'] ? true : false}
                  />
                  <FormText>Use spaces to add multiples </FormText>
                  <FormFeedback >{this.state.error['roll']}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail">
                    Shipping Date
                  </Label>
                  <Input
                    name="shipping_date"
                    type="date"
                    onChange={this.onChange}
                    required
                    invalid={this.state.error['shipping_date'] ? true : false}
                  />
                  <FormFeedback >{this.state.error['shipping_date']}</FormFeedback>
                </FormGroup>
              </Form>
              <div className="col-md-7 border-l-2">
                <div className="text-lg text-muted">Preview Data</div>
                <DataTable
                  // progressPending={this.state.loadingData}
                  noHeader={true}
                  columns={[
                    {
                      name: 'No.',
                      selector: 'no',
                      width: '10%',
                    },
                    {
                      name: 'Serial Number',
                      selector: 'serial',
                      sortable: true,
                      width: '50%'
                    },
                    {
                      name: 'Roll Number',
                      sortable: true,
                      cell: data => data.roll ?? '-',
                      width: '15%'
                    },
                    {
                      name: 'Qty',
                      sortable: true,
                      width: '25%',
                      cell: data => addCommas(data.qty),
                    },
                  ]}
                  data={this.state.previewDatas}
                  pagination={false}
                  noDataComponent={"You have not entered a serial range."}
                  customStyles={{
                    rows: {
                      style: {
                        minHeight: '30px', // override the row height
                        padding: '0 8px', // override the row padding
                      },
                    },
                    headCells: {
                      style: {
                        padding: '0 8px', // override the head cell padding
                      },
                    },
                    cells: {
                      style: {
                        padding: '0 8px', // override the cell padding
                      },
                    },
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={`w-full d-flex justify-content- ${this.state.buttonLoading ? 'between' : 'between'}`}>
            <div className="d-flex justify-content-start" style={{ maxWidth: '75%' }}>
              {
                this.state.buttonLoading ?
                  <div className="text-muted text-sm">The Download csv file button will appear once it is complete. Please do not close/refresh this page.</div>
                  : null
              }
            </div>
            <div className="d-flex gap-2">
              {
                this.state.downloadPath ? <Button color="primary" href={this.state.downloadPath} download>Download CSV</Button>
                  : <>
                    <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <>Process <Spinner size="sm" /></>}
                    </Button>
                    {
                      this.state.buttonLoading ? null : <Button color="danger" onClick={() => this.props.close()}>Cancel</Button>
                    }
                  </>
              }
            </div>
          </ModalFooter>
        </Modal>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
      </>
    )
  }
}